@import "core";
.section.event-calendar {
	.calendar-title {
		@include font-bold;
		font-size: 2rem;

		&.placeholder {
			width: 60%;
			height: 2rem;
		}
	}

	.calendar-filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 2.4rem;
	}

		.filters-locations {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

			.locations-title {
				@include font-bold;
				font-size: 1.2rem;
				margin-right: 2rem;
			}

			.locations-item {
				display: flex;
				align-items: center;
				font-size: 1.6rem;

				+ .locations-item {
					margin-left: 1.8rem;
				}

				&.selected {
					@include font-bold;

					.item-check {
						background-color: $color-primary;
						color: $color-white;

						&:before {
							@include icon('check');
						}
					}
				}
			}

				.item-check {
					width: 2rem;
					height: 2rem;
					border-radius: $radius-small;
					background-color: $color-bg;
					margin-right: 1rem;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: .9rem;
				}

	.calendar-list {
		border: .1rem solid $color-border;
		border-radius: $radius-general;
		padding: 0 2.4rem;
		margin-top: 2rem;

		@include media {
			padding: 0 2rem;
		}
	}

		.list-event {
			display: flex;
			align-items: center;
			padding: 2.2rem 0;

			@include media {
				padding: 2rem 0;
			}

			+ .list-event {
				border-top: .1rem solid $color-border;
			}
		}

			.event-datetime {
				@include font-bold;
				display: flex;
				text-align: center;
				border: .1rem solid $color-border;
				border-radius: $radius-small;
				flex-direction: column;
				width: 3.6rem;
				font-size: 1.2rem;
				overflow: hidden;
				margin-right: 2rem;

				&.placeholder {
					height: 5rem;
					border: none;
				}
			}

				.datetime-day {
					font-size: 1.8rem;
					color: $color-white;
					background-color: $color-primary;
					line-height: 2.5rem;
					width: 100%;
				}

				.datetime-month {
					line-height: 2.2rem;
				}

			.event-text {
				flex: 1 0 0;
			}

			.event-title {
				@include font-medium;
				display: block;
				font-size: 1.6rem;

				&.placeholder {
					width: 80%;
					height: 1.7rem;
				}
			}

			.event-details {
				@include font-bold;
				display: block;
				margin-top: .3rem;
				font-size: 1.4rem;

				&.placeholder {
					height: 1.6rem;
					width: 60%;
				}
			}

			.event-controls {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-left: 1rem;
			}

			.event-buybtn {
				&.placeholder {
					width: 10rem;
					height: 4rem;
				}
			}

			.event-detailsbtn {
				margin-top: 1rem;
				
				&.placeholder {
					width: 7.2rem;
					height: 1.6rem;
				}
			}
}