@import "core";
.section.event-ticketoptions {
	.ticketoptions-title {
		@include font-bold;
		font-size: 2rem;

		&.placeholder {
			width: 60%;
			height: 2rem;
		}
	}

	.ticketoptions-list {
		margin-top: 1rem;
	}

		.list-option {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem 0;
			
			// @include media {
			// 	padding: 2rem 0;
			// }
			
			+ .list-option {
				border-top: .1rem solid $color-border;
			}
		}
			
			.option-title {
				@include font-regular;
				flex: 1 0 0;
			}

			.option-buybtn {
				margin-left: 1.5rem;
			}
}