@import "core";
.emptymessage {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem auto;
	// max-width: 60rem;
	color: $color-text-light;
	border: .1rem solid $color-border;
	padding: 2rem;
	border-radius: $radius-general;

	.emptymessage-icon {
		font-size: 3.4rem;
		margin-right: 2rem;
	}

	.emptymessage-title {
		@include font-extrabold;
		display: block;
		margin-bottom: .5rem;
	}

	.emptymessage-content {
		@include font-medium;
		font-size: 1.4rem;
		line-height: 1.3em;

		strong {
			@include font-extrabold;
		}
	}
}