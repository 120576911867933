@import "core";
.section.event-programme {
	.programme-title {
		@include font-bold;
		font-size: 2rem;

		&.placeholder {
			width: 60%;
			height: 2rem;
		}
	}

	// .programme-nav {
	// 	margin-top: 2rem;
	// }

	.programme-tabs {
		margin-top: 2rem;

		@include media {
			&.tabs-container .item-btn {
				font-size: 1.6rem;
				line-height: 2rem;
			}
		}
	}

	.programme-list {
		border: .1rem solid $color-border;
		border-radius: $radius-general;
		padding: 0 2.4rem;

		@include media {
			padding: 0 2rem;
		}
	}

		.list-event {
			display: flex;
			align-items: center;
			padding: 2.2rem 0;

			@include media {
				padding: 2rem 0;
			}

			+ .list-event {
				border-top: .1rem solid $color-border;
			}
		}

			.event-image {
				width: 3.8rem;
				margin-right: 1rem;
			}

				.image-wrap {
					padding-bottom: $event-image-ratio;
					position: relative;
				}

				.image-image {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
				}

			.event-text {
				flex: 1 0 0;
			}

			.event-title {
				@include font-medium;
				display: block;
				font-size: 1.6rem;

				&.placeholder {
					width: 80%;
					height: 1.7rem;
				}
			}

			.event-details {
				@include font-bold;
				color: $color-text-light;
				display: block;
				margin-top: .5rem;
				font-size: 1.4rem;

				&.placeholder {
					height: 1.6rem;
					width: 60%;
				}
			}

			.event-controls {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-left: 1rem;
			}

			.event-buybtn {
				&.placeholder {
					width: 10rem;
					height: 4rem;
				}
			}

			.event-detailsbtn {
				margin-top: 1rem;
				
				&.placeholder {
					width: 7.2rem;
					height: 1.6rem;
				}
			}
}