@import "core";
@import "@wearetla/tla-essentials-tools/partials/placeholder/placeholder-mixins";

@include placeholder-styles($background: $color-bg, $sweeperColor: $color-white, $radius: $radius-small);

.placeholder-elem.light {
    background-color: $color-white;

    &:before {
        background-image: linear-gradient(to left, rgba($color-bg, .05), rgba($color-bg, .3), rgba($color-bg, .6), rgba($color-bg, .3), rgba($color-bg, .05));
    }
}