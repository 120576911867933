@import "core";
.page-event-detail {
	.layout-base-content {
		padding: 4.2rem 0 6rem;

		@include media {
			padding: 0 0 3rem;
			position: relative;
		}
	}

	.detail-nav {
		padding: 0 0 2rem;
		position: relative;
		z-index: 2;

		@include media {
			@include font-medium;
			position: absolute;
			top: 0rem;
			left: 0;
			width: 100%;
			letter-spacing: .02em;
			white-space: nowrap;
			overflow-y: scroll;
			padding: 1rem 0 3rem;
			color: $color-white;
			background: transparent linear-gradient(180deg, rgba($color-black, 1) 0%, rgba($color-black, 0) 100%) 0% 0% no-repeat padding-box;
		}
	}

	.nav-backbtn {
		padding: 1rem 1rem 1.3rem 2rem;
		color: $color-white;
		display: inline-flex;
		align-items: center;
		
		.icon {
			font-size: 1.8rem;
			margin-right: .5rem;
		}
	}

	// .nav-breadcrumbs {
	// }

		.breadcrumbs-list {
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			line-height: 1.6rem;
		}

			.list-item {
				display: flex;
				&:not(:last-child) {
					&:after {
						margin: 0 .5rem;
						font-size: 1rem;
						content: '>';
					}
				}
			}

				.item-link {
					&.placeholder {
						display: inline-block;
						width: 8rem;
						height: 1.7rem;
					}
				}

	.detail-wrap {
		display: flex;
		align-items: flex-start;
	}

	.detail-content {
		width: 48rem;
		margin-right: 2rem;

		@include media {
			display: block;
			width: 100%;
			margin-right: 0;
		}
	}

	.detail-info {
		background-color: $color-white;
		position: relative;
		z-index: 2;
		border-radius: $radius-general;
		border: .1rem solid $color-border;
		padding: 1.4rem 2rem 2.6rem;

		@include media {
			padding: 2rem;
			min-height: 31rem;
		}
	}

		.info-title {
			@include font-bold;
			font-size: 3.2rem;
			line-height: 3.9rem;

			&.placeholder {
				width: 90%;
				height: 3.9rem;
			}

			&:last-child {
				margin-bottom: -1.2rem;
			}
		}

			.title-subtitle {
				display: block;
				color: $color-text-light;
				font-size: .6em;
			}

		.info-eventlink {
			display: inline-block;
			margin-top: .5rem;
			color: $color-text-light;
			text-decoration: underline;

			&.placeholder {
				width: 10.3rem;
				max-width: 90%;
				height: 1.4rem;
			}
		}

		.info-shortdescription {
			margin-top: 2rem;
			color: $color-text-light;
			line-height: 1.4em;

			&.placeholder {
				height: 2.8rem;
				width: 80%;
			}

		}

		// .info-fields {
		// 	margin-top: 5.6rem;

		// 	.fields-field {
		// 		&.placeholder {
		// 			display: inline-block;
		// 			width: 20rem;
		// 			height: 3.7rem;

		// 			+ .fields-field {
		// 				margin-left: .7rem;
		// 			}
		// 		}
		// 	}

		// 	@include media {
		// 		margin-top: 2rem;

		// 		.fields-field {
		// 			margin-left: 0;
		// 			margin-bottom: 1rem;
		// 			margin-right: .5rem;
	
		// 			&:last-child {
		// 				margin-bottom: 0;
		// 				margin-right: 0;
		// 			}
		// 		}
		// 	}

		// 	+ .infu-subfields {
		// 		margin-top: 2rem;
		// 		border-top: .1rem solid $color-border-light;

		// 		@include media {
		// 			margin-top: 1.4rem;
		// 		}
		// 	}
		// }

		.info-subfields {
			padding-top: 1rem;
			display: flex;
			flex-wrap: wrap;
			width: calc(100% + 1.5rem);
			margin-right: -1rem;

			@include media {
				padding-top: .5rem;
			}
		}

			.subfields-field {
				@include font-bold;
				font-size: 1.6rem;
				margin-right: 1.5rem;
				margin-top: 1.5rem;

				&.placeholder {
					width: 45%;
					height: 1.6rem;
				}
			}

				.field-icon {
					margin-right: .8rem;
					font-size: 1.5rem;
					// width: 2.2rem;
					display: inline-block;
					text-align: center;
				}
		
		.info-heromessage {
			@include font-medium;
			margin-top: 2.2rem;
			border-radius: $radius-general;
			background-color: $color-bg;
			padding: 2rem 2rem;
	
		}

		.info-ticketoptions {
			margin-top: 2.2rem;
			border-top: .1rem solid $color-border;
			padding-top: 2.2rem;
	
			@include media {
				margin-top: 2rem;
				padding-top: 2rem;
			}
		}

		.info-buybtn {
			margin-top: 2.2rem;

			&.placeholder {
				width: 15rem;
				height: 5rem;
			}
		}

		.info-optionstrigger {
			text-align: center;
			padding: 1.5rem 0 1.5rem;
			margin-top: 1.5rem;
			border-top: .1rem solid $color-border;
			border-bottom: .1rem solid $color-border;
		}

			.optionstrigger-btn {
				.btn-icon {
					font-size: .8em;
				}
			}

	.detail-image {
		@include mediaMin {
			flex: 1 0 0;
			width: calc(100% - 50rem);
		}

		@include media {
			position: relative;
			z-index: 1;
			height: 48.1rem;
			max-width: none;
			width: calc(100% + 3.6rem);
			margin: 0 -1.8rem -26rem;
			border-radius: 0;
		}
	}
	
		.image-imagewrap {
			position: relative;
			padding-bottom: $event-image-ratio;
			overflow: hidden;
			
			@include mediaMin {
				border-radius: $radius-general;
			}

			.img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			&.placeholder {
				background-color: $color-gray;
			}
		}

	.detail-additions {
		margin-top: 5rem;

		@include media {
			margin-top: 3rem;
		}

		.labels-innerwrap {
			justify-content: flex-start;
		}
	}

		.additions-textfield {
			font-size: 1.6rem;
			line-height: 2em;
		}

	.detail-eventcalendar {
		margin-top: 2.2rem;

		@include media {
			margin-top: 1.8rem;
		}
	}

	.detail-eventprogramme {
		margin-top: 2.2rem;

		@include media {
			margin-top: 1.8rem;
		}
	}
}