@import "core";
.tabs-container {

	.tabs-labels {
		position: relative;
		border-bottom: .1rem solid $color-black;
	}

		.labels-innerwrap {
			display: flex;
		}

		.labels-item {
			&.active {
				color: $color-primary;

				.item-btn {
					border-bottom-color: $color-primary;
					
					span {
						transform: scale(1);
					}
				}
			}
		}

		.item-btn {
			@include font-bold;;
			font-size: 2rem;
			padding: 0 0 1.1rem;
			line-height: 2.3rem;
			display: block;
			margin-bottom: -.2rem;
			border-bottom: .3rem solid transparent;

			span {
				transition: transform $transition-hover ease;
				transform: scale(0.7);
				display: block;
			}
		}

	.tabs-items {
		padding-top: 2rem;
	}
}